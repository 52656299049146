import React from "react";

export interface Props {
  value: number;
}

export const DurationDisplay = ({ value }: Props) => {
  const seconds = value % 60;
  const minutes = ((value - seconds) / 60) % 60;
  const hours = (value - seconds - minutes * 60) / (60 * 60);

  return (
    <span>
      {hours}&nbsp;hours {minutes}&nbsp;min {seconds}&nbsp;sec
    </span>
  );
};

export default DurationDisplay;
