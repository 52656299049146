import React, { useContext, useEffect, useMemo, useState } from "react";
import { graphql, PageProps } from "gatsby";
import { Col, Row } from "react-bootstrap";
import QueryString from "query-string";

import { Video, videoFromNode } from "../data/videos";
import "../styles/main.scss";
import { maxDuration } from "../components/DurationPicker";
import Layout from "../layouts/default";
import DurationDisplay from "../components/DurationDisplay";
import { db } from "../db";
import SearchResult from "../components/SearchResult";
import VideoFilterOptions from "../components/VideoFilterOptions";
import { PageNavigation } from "../components/PageNavigation";
import WelcomeMessage from "../components/WelcomeMessage";
import { VideoBrowseContext } from "../models/browse-context";
import PageIcon from "../components/PageIcon";

export const resultPageSize = 8;

// We mark a video as recently viewed if it has been viewed since some
// threshold (currently 28 days ago)
const recentViewThreshold = new Date(
  new Date().getTime() - 28 * 24 * 60 * 60 * 1000
);

type VideoNode =
  PageProps<Queries.VideoIndexQuery>["data"]["allVideosYaml"]["nodes"][number];

export const Head = () => (
  <>
    <title>MandarInput: Comprehensible Input for Mandarin Chinese</title>
    <script
      async
      src="https://c6.patreon.com/becomePatronButton.bundle.js"
    ></script>
    <PageIcon />
  </>
);

const IndexPage = ({
  data,
  location,
  pageContext,
}: PageProps<Queries.VideoIndexQuery>) => {
  const pageNumber = ("page" in pageContext ? pageContext.page : 0) as number;

  const browseContext = useContext(VideoBrowseContext);

  const videos: readonly VideoNode[] = data.allVideosYaml.nodes;

  const topics = useMemo(() => {
    const topics = new Set<string>();
    for (const video of videos) {
      for (const topic of video.topics ?? []) {
        if (topic !== null) {
          topics.add(topic);
        }
      }
    }

    return Array.from(topics).map((topic) => ({
      value: topic,
      label: topic,
    }));
  }, [videos]);

  const filter = browseContext.filter;

  // This only includes recently viewed videos. We keep track of all watches,
  // but decided only to show this on views within a certain threshold. We
  // could add another collection in the future if we want both
  // recently-watched and ever-watched.
  const [viewedVideos, setViewedVideos] = useState<Set<string> | undefined>(
    undefined
  );

  useEffect(() => {
    const searchParams = QueryString.parse(location.search, {
      arrayFormat: "comma",
    });

    if (searchParams.topics !== undefined) {
      const topics = searchParams.topics as string[];
      browseContext.setFilter({
        ...browseContext.filter,
        topics,
      });
    }
  }, [location.search]);

  useEffect(() => {
    const fetch = async () => {
      const views = await db.videoViews
        .where("when")
        .aboveOrEqual(recentViewThreshold)
        .toArray();
      const viewEventIds = views.map((view) => view.videoId);

      const viewedVideos = new Set<string>();
      for (const videoId of viewEventIds) {
        viewedVideos.add(videoId);
      }

      setViewedVideos(viewedVideos);
    };
    fetch();
  }, []);

  const filterByTopic = (video: VideoNode) => {
    if (filter.topics.length === 0) {
      return true;
    }

    for (const topic of video.topics ?? []) {
      if (topic !== null && filter.topics.includes(topic)) {
        return true;
      }
    }

    return false;
  };

  const filterByDuration = (video: VideoNode) => {
    if (video.duration === null) {
      // Not much we can do here
      return true;
    }

    if (filter.duration.min > 0 && video.duration < filter.duration.min) {
      return false;
    }

    if (
      filter.duration.max < maxDuration &&
      video.duration > filter.duration.max
    ) {
      return false;
    }

    return true;
  };

  const filterByDifficulty = (video: VideoNode) => {
    if (video.difficulty === null) {
      return true;
    }

    if (video.difficulty < filter.difficulty.min) {
      return false;
    }

    if (video.difficulty > filter.difficulty.max) {
      return false;
    }

    return true;
  };

  const filterBySubtitles = (video: VideoNode) => {
    if (
      filter.subtitles.english === "yes" &&
      (video.subtitles?.english === undefined ||
        video.subtitles.english === "no")
    ) {
      return false;
    }

    if (
      filter.subtitles.chinese === "yes" &&
      (video.subtitles?.chinese === undefined ||
        video.subtitles.chinese === "no")
    ) {
      return false;
    }

    if (
      filter.subtitles.pinyin === "yes" &&
      (video.subtitles?.pinyin === undefined || video.subtitles.pinyin === "no")
    ) {
      return false;
    }

    if (
      filter.subtitles.english === "no" &&
      (video.subtitles?.english === undefined ||
        video.subtitles.english === "yes")
    ) {
      return false;
    }

    if (
      filter.subtitles.chinese === "no" &&
      (video.subtitles?.chinese === undefined ||
        video.subtitles.chinese === "yes")
    ) {
      return false;
    }

    if (
      filter.subtitles.pinyin === "no" &&
      (video.subtitles?.pinyin === undefined ||
        video.subtitles.pinyin === "yes")
    ) {
      return false;
    }

    return true;
  };

  const filterByWatched = (video: VideoNode) => {
    if (
      filter.watched === undefined ||
      viewedVideos === undefined ||
      video.videoId === null
    ) {
      return true;
    }

    return viewedVideos.has(video.videoId) == filter.watched;
  };

  const filteredVideos: Video[] = videos
    .filter(
      (video) =>
        filterByTopic(video) &&
        filterByDuration(video) &&
        filterByDifficulty(video) &&
        filterBySubtitles(video) &&
        filterByWatched(video)
    )
    .map(videoFromNode)
    .filter((x: Video | null): x is Video => x !== null);

  const totalDuration = filteredVideos.reduce(
    (total: number, current: Video) => {
      return total + current.duration;
    },
    0
  );

  const numPages = Math.ceil(filteredVideos.length / resultPageSize);

  // The page can end up being higher than any valid page if we've changed the
  // filter while a high page is selected. Ensure it is within range.
  const currentPage = Math.min(numPages - 1, pageNumber);

  const pageVideos = filteredVideos.slice(
    currentPage * resultPageSize,
    (currentPage + 1) * resultPageSize
  );

  const pageNavigation = (
    <PageNavigation
      maxButtons={10}
      currentPageNumber={currentPage}
      maxPageNumber={numPages - 1}
    />
  );

  return (
    <>
      <Layout>
        <Row>
          <Col sm={12}>
            <WelcomeMessage />
          </Col>
        </Row>

        <Row>
          <Col sm={12} md={6} lg={3}>
            <div className="card">
              <h2>Filters</h2>
              <VideoFilterOptions
                topics={topics}
                filter={filter}
                onChangeFilter={browseContext.setFilter}
              />
            </div>

            <p>
              Found {filteredVideos.length} video
              {filteredVideos.length !== 1 ? "s" : ""}, totalling{" "}
              <DurationDisplay value={totalDuration} /> in duration.
            </p>

            <a
              href="https://www.patreon.com/bePatron?u=575577"
              data-patreon-widget-type="become-patron-button"
            >
              Become a member!
            </a>
          </Col>
          <Col sm={12} md={6} lg={9}>
            {pageNavigation}

            {pageVideos.map((video) => (
              <SearchResult
                key={video.videoId}
                video={video}
                viewed={viewedVideos?.has(video.videoId) ?? false}
              />
            ))}

            {pageNavigation}
          </Col>
        </Row>
      </Layout>
    </>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  query VideoIndex {
    allVideosYaml(sort: { fields: [publishedAt], order: DESC }) {
      nodes {
        slug
        duration
        difficulty
        channel
        topics
        title
        videoId
        subtitles {
          chinese
          english
          pinyin
        }
        speechSpeed
      }
    }
  }
`;
