import React, { useEffect, useState } from "react";
import { Row, Col, Card } from "react-bootstrap";

// Only show the welcome message the first few times the user loads this page.
const maxViews = 3;

const itemKey = "welcome-message/view-count";

function useUserIsNew() {
  const [userIsNew, setUserIsNew] = useState(false);

  useEffect(() => {
    if (typeof window !== "undefined") {
      const storageItem = window.localStorage.getItem(itemKey);

      let numViews: number;
      if (storageItem !== null) {
        numViews = parseInt(storageItem, 10);
        if (isNaN(numViews)) {
          numViews = 0;
        }
      } else {
        numViews = 0;
      }

      if (numViews < maxViews) {
        setUserIsNew(true);
      }

      window.localStorage.setItem(itemKey, (numViews + 1).toString());
    } else {
      // Server-side rendering, always show the welcome message
      setUserIsNew(true);
    }
  }, []);

  if (typeof window === "undefined") {
    // For server-side rendering, we want to show this content unconditionally
    // (since there's no user to detect the state of)
    return true;
  } else {
    return userIsNew;
  }
}

export default function WelcomeMessage() {
  const userIsNew = useUserIsNew();

  if (userIsNew) {
    return (
      <Row>
        <Col xs={12} md={6}>
          <Card>
            <h1>Learn Mandarin, naturally</h1>
            <p>
              Mandarinput helps people who are learning Mandarin Chinese to
              practise their language in an easy, natural way. We help you to
              find videos that are at your level, on topics you are interested
              in.
            </p>
          </Card>
        </Col>

        <Col xs={12} md={6}>
          <Card>
            <h1>What is Comprehensible Input?</h1>
            <p>
              It&apos;s one of the simplest and best ways to generate real
              fluency in a language without all the hard work.
            </p>

            <p>
              If you listen to things in your target language that are at a
              level you can understand, your brain will automatically build
              familiarity with the language that will help you to express
              yourself too.
            </p>
          </Card>
        </Col>
      </Row>
    );
  } else {
    return null;
  }
}
