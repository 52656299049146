import React from "react";
import { Pagination } from "react-bootstrap";
import { Link } from "gatsby";
import classNames from "classnames";

export interface Props {
  maxButtons: number;

  currentPageNumber: number;
  maxPageNumber: number;
}

interface ItemProps extends React.HTMLAttributes<HTMLElement> {
  active: boolean;
  disabled: boolean;
  to: string;
}

// Replace the standard react-bootstrap Pagination.Item with one that uses
// a Gatsby router link.
const GatsbyLinkItem = ({ active, disabled, to, children }: ItemProps) => {
  let linkItem: React.ReactNode;

  if (active || disabled) {
    linkItem = <span className="page-link">{children}</span>;
  } else {
    linkItem = (
      <Link className="page-link" to={to}>
        {children}
      </Link>
    );
  }

  return <li className={classNames("page-item", { active })}>{linkItem}</li>;
};

interface ButtonProps extends React.HTMLAttributes<HTMLElement> {
  disabled: boolean;
  to: string;
}

const GatsbyPreviousButton = ({ disabled, to, children }: ButtonProps) => {
  return (
    <GatsbyLinkItem active={false} disabled={disabled} to={to}>
      <span aria-hidden="true">{children || "‹"}</span>
    </GatsbyLinkItem>
  );
};

const GatsbyNextButton = ({ disabled, to, children }: ButtonProps) => {
  return (
    <GatsbyLinkItem active={false} disabled={disabled} to={to}>
      <span aria-hidden="true">{children || "›"}</span>
    </GatsbyLinkItem>
  );
};

// Show page navigation, with an ellipsis to make sure that it isn't too
// wide on narrow screens.
export const PageNavigation = ({
  maxButtons,
  maxPageNumber,
  currentPageNumber,
}: Props) => {
  const makePageButton = (page: number) => (
    <GatsbyLinkItem
      key={`page${page}`}
      active={currentPageNumber == page}
      disabled={false}
      to={page == 0 ? "/" : `/page/${page + 1}/`}
    >
      {page + 1}
    </GatsbyLinkItem>
  );

  const paginationItems: React.ReactElement[] = [];
  for (let linkPage = 0; linkPage <= maxPageNumber; linkPage++) {
    paginationItems.push(makePageButton(linkPage));
  }

  // Now check whether there are too many items, and remove some if necessary.
  if (paginationItems.length + 2 > maxButtons) {
    // Is there room for a lower ellipsis?
    const lowerEllipsis = currentPageNumber > 3;

    // Is there room for an upper ellipsis?
    const upperEllipsis = maxPageNumber - currentPageNumber > 3;

    if (upperEllipsis) {
      if (lowerEllipsis) {
        // Trying to fit in two ellipsese, so splice out the maximum number of elements
        // above the current page
        paginationItems.splice(
          currentPageNumber + 2,
          maxPageNumber - currentPageNumber - 2,
          <Pagination.Ellipsis key="upper_ellipsis" />
        );
      } else {
        // Only have the one ellipsis, don't splice out more than we have to
        const numToRemove = paginationItems.length + 4 - maxButtons;
        paginationItems.splice(
          paginationItems.length - numToRemove - 1,
          numToRemove,
          <Pagination.Ellipsis key="upper_ellipsis" />
        );
      }
    }

    if (lowerEllipsis) {
      if (upperEllipsis) {
        // Splice out the maximum number of elements below the current page
        paginationItems.splice(
          1,
          currentPageNumber - 2,
          <Pagination.Ellipsis key="eliipsis1" />
        );
      } else {
        // Only have one ellipsis, don't splice out more than we have to
        const numToRemove = paginationItems.length + 4 - maxButtons;
        paginationItems.splice(
          1,
          numToRemove,
          <Pagination.Ellipsis key="lower_ellipsis" />
        );
      }
    }
  }

  // The numbers in the URLs are 1-indexed, but the page numbers within this
  // component are zero-indexed.
  return (
    <Pagination>
      <GatsbyPreviousButton
        key="previous"
        disabled={currentPageNumber <= 0}
        to={currentPageNumber == 1 ? "/" : `/page/${currentPageNumber}`}
      />
      {paginationItems}
      <GatsbyNextButton
        key="next"
        disabled={currentPageNumber >= maxPageNumber}
        to={`/page/${currentPageNumber + 2}`}
      />
    </Pagination>
  );
};
