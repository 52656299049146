import React from "react";
import { Form, Button } from "react-bootstrap";
import { debounce } from "lodash";
import Select from "react-select";

import DifficultyPicker from "./DifficultyPicker";
import DurationPicker from "./DurationPicker";
import { defaultVideoFilter, isBrowser } from "../utils";

export interface SubtitleChoices {
  english: "yes" | "no" | "either";
  chinese: "yes" | "no" | "either";
  pinyin: "yes" | "no" | "either";
}

export interface VideoFilter {
  topics: string[];
  duration: { max: number; min: number };
  difficulty: { max: number; min: number };
  subtitles: SubtitleChoices;
  watched?: boolean;
}

export interface Props {
  topics: { value: string; label: string }[];
  filter: VideoFilter;
  onChangeFilter: (newFilter: VideoFilter) => void;
}

export const VideoFilterOptions = ({
  topics,
  filter,
  onChangeFilter,
}: Props) => {
  const sendAnalyticsEvent = React.useMemo(
    () =>
      debounce((filter: VideoFilter) => {
        if (window.analytics !== undefined) {
          window.analytics.track("Video filter changed", filter);
        }
      }, 500),
    [isBrowser && window.analytics]
  );

  const updateFilter = (newFilter: VideoFilter) => {
    sendAnalyticsEvent(newFilter);
    onChangeFilter(newFilter);
  };

  const setSubtitleChoices = (
    key: "english" | "chinese" | "pinyin",
    value: "yes" | "no" | "either"
  ) => {
    updateFilter({
      ...filter,
      subtitles: { ...filter.subtitles, [key]: value },
    });
  };

  return (
    <Form>
      <Form.Group>
        <Form.Label>Topics</Form.Label>
        <Select
          isMulti
          options={topics}
          value={topics.filter((topic) => filter.topics.includes(topic.value))}
          onChange={(value) => {
            updateFilter({
              ...filter,
              topics: value.map((val) => val.value),
            });
          }}
        />
      </Form.Group>

      <Form.Group>
        <Form.Label>Difficulty</Form.Label>
        <DifficultyPicker
          value={filter.difficulty}
          onChange={(newDifficulty) =>
            updateFilter({ ...filter, difficulty: newDifficulty })
          }
        />
      </Form.Group>

      <Form.Group>
        <Form.Label>Duration</Form.Label>
        <DurationPicker
          value={filter.duration}
          onChange={(newDuration) =>
            updateFilter({ ...filter, duration: newDuration })
          }
        />
      </Form.Group>

      <Form.Group>
        <Form.Label>Subtitles</Form.Label>
        <div>
          English:&nbsp;
          <Form.Check
            inline
            label="yes"
            name="subtitlesEnglish"
            type="radio"
            id="subtitlesEnglishYes"
            onChange={() => setSubtitleChoices("english", "yes")}
            checked={filter.subtitles.english === "yes"}
          />
          <Form.Check
            inline
            label="no"
            name="subtitlesEnglish"
            type="radio"
            id="subtitlesEnglishNo"
            onChange={() => setSubtitleChoices("english", "no")}
            checked={filter.subtitles.english === "no"}
          />
          <Form.Check
            inline
            label="either"
            name="subtitlesEnglish"
            type="radio"
            id="subtitlesEnglishEither"
            onChange={() => setSubtitleChoices("english", "either")}
            checked={filter.subtitles.english === "either"}
          />
        </div>
        <div>
          Chinese:&nbsp;
          <Form.Check
            inline
            label="yes"
            name="subtitlesChinese"
            type="radio"
            id="subtitlesChineseYes"
            onChange={() => setSubtitleChoices("chinese", "yes")}
            checked={filter.subtitles.chinese === "yes"}
          />
          <Form.Check
            inline
            label="no"
            name="subtitlesChinese"
            type="radio"
            id="subtitlesChineseNo"
            onChange={() => setSubtitleChoices("chinese", "no")}
            checked={filter.subtitles.chinese === "no"}
          />
          <Form.Check
            inline
            label="either"
            name="subtitlesChinese"
            type="radio"
            id="subtitlesChineseEither"
            onChange={() => setSubtitleChoices("chinese", "either")}
            checked={filter.subtitles.chinese === "either"}
          />
        </div>
        <div>
          Pinyin:&nbsp;
          <Form.Check
            inline
            label="yes"
            name="subtitlesPinyin"
            type="radio"
            id="subtitlesPinyinYes"
            onChange={() => setSubtitleChoices("pinyin", "yes")}
            checked={filter.subtitles.pinyin === "yes"}
          />
          <Form.Check
            inline
            label="no"
            name="subtitlesPinyin"
            type="radio"
            id="subtitlesPinyinNo"
            onChange={() => setSubtitleChoices("pinyin", "no")}
            checked={filter.subtitles.pinyin === "no"}
          />
          <Form.Check
            inline
            label="either"
            name="subtitlesPinyin"
            type="radio"
            id="subtitlesPinyinEither"
            onChange={() => setSubtitleChoices("pinyin", "either")}
            checked={filter.subtitles.pinyin === "either"}
          />
        </div>
      </Form.Group>

      <Form.Group>
        <Form.Label>Recently watched?</Form.Label>
        <div>
          <Form.Check
            inline
            label="yes"
            name="watched"
            type="radio"
            id="watchedYes"
            onChange={() => updateFilter({ ...filter, watched: true })}
            checked={filter.watched === true}
          />
          <Form.Check
            inline
            label="no"
            name="watched"
            type="radio"
            id="watchedNo"
            onChange={() => updateFilter({ ...filter, watched: false })}
            checked={filter.watched === false}
          />
          <Form.Check
            inline
            label="either"
            name="watched"
            type="radio"
            id="watchedEither"
            onChange={() => updateFilter({ ...filter, watched: undefined })}
            checked={filter.watched === undefined}
          />
        </div>
      </Form.Group>
      <Form.Group>
        <Button
          variant="primary"
          onClick={() => {
            updateFilter(defaultVideoFilter);
          }}
        >
          Reset
        </Button>
      </Form.Group>
    </Form>
  );
};

export default VideoFilterOptions;
